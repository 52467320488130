<template>
    <el-scrollbar class="booth-ad-content">
        <!-- <el-breadcrumb style="padding: 22px 40px 0">
            <el-breadcrumb-item>运营推广</el-breadcrumb-item>
            <el-breadcrumb-item>数据分析</el-breadcrumb-item>
            <el-breadcrumb-item>展位广告</el-breadcrumb-item>
            <el-breadcrumb-item>  广告数据</el-breadcrumb-item>
        </el-breadcrumb> -->
        <div class="big-title">广告数据</div>
        <div class="chart-tab">
            <div class="chart-tab-header">
                <span>展位广告</span>
            </div>
            <div class="chart-tab-title">趋势分析</div>
            <el-checkbox-group v-model="checkList">
                <el-checkbox :label="checkItem" v-for="checkItem in categoryList" :key="checkItem"></el-checkbox>
            </el-checkbox-group>
            <div class="data-content" id="boothAd"></div>
        </div>

        <div class="chart-tab">
            <div class="tab-title">
                    <div class="popover-title">地区分布</div>
                    <div>
                    成交客户主要集中在
                    <span style="color: #ff0000;"
                          v-for="(customerItem, customerIndex) in customerList" :key="`customerList_${customerIndex}`">
                    {{customerItem.provinces}}<span v-if="customerIndex !== customerList.length - 1">、</span>
                    </span>等省份
                </div>
            </div>
            <el-table :data="areaList" border style="width: 100%; margin-top: 20px; flex: 1" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '48px', color: '#666666', background: '#F5F5F5'}"
                      :cell-style="{fontSize: '14px',color: '#343434'}">
                <el-table-column align="center" label="排名" width="60">
                    <template slot-scope="scope">
                        <div class="ranking" v-if="scope.row.index === 1 || scope.row.index === 2 || scope.row.index === 3">
                            {{scope.row.index}}
                        </div>
                        <div v-else class="ranking-general">{{scope.row.index < 10 ? `0${scope.row.index}` : scope.row.index}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="provinces" align="center" label="地区"></el-table-column>
                <el-table-column align="center" label="点击率">
                    <template slot-scope="scope">
                        {{scope.row.click_rate}}%
                    </template>
                </el-table-column>
                <el-table-column align="center" label="转化率">
                    <template slot-scope="scope">
                        {{scope.row.conversion_rate}}%
                    </template>
                </el-table-column>
                <el-table-column align="left" label="占比">
                    <template slot-scope="scope">
                        <div class="area-item">
                            <el-progress class="area-process" :percentage="scope.row.percentage" :show-text="false"></el-progress>
                            <div class="percentage">{{scope.row.percent}}%</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="operation-pagination"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           :total="listPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="handleCurrentChange">
            </el-pagination>
        </div>
    </el-scrollbar>
</template>

<script>
    import { StuOpProDataTimeAnalysis, StuOpProGetLocationAnalysisData } from '@/utils/apis.js'
    export default {
        data() {
            return {
                boothAd: null,
                checkList: ['展现次数', '点击率', '转化率'],
                categoryList: ['展现次数', '点击率', '转化率'],
                allSeries: [],
                customerList: [],
                //地区分布
                areaList: [],
                //分页
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                denominator: null,
                maxShowData:0,
                maxclickData:0,
                maxconversion:0
            }
        },
        mounted(){
            this.getBoothAdInfo();
            this.getAreaList()
            this.boothAd = this.$echarts.init(document.getElementById('boothAd'));

            let resizeTimer = null;
            window.onresize = () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    this.boothAd.resize()
                }, 100)
            }
        },
        computed: {
            series() {
                return this.allSeries.filter(item => {
                    return this.checkList.includes(item.name)
                })
            }
        },
        watch: {
            series(val) {
                this.getTrendECharts()
            }
        },
        methods: {
            // 获取eChart图表
            getBoothAdInfo() {
                let params = {
                    op_id: this.$route.query.op_id
                }
                StuOpProDataTimeAnalysis(params).then((res) => {
                    // 展现次数
                    let potentialCustomersArr = res.data.map(item => {
                        return item.potential_customers
                    })
                    this.maxShowData=Math.ceil(Math.max(...potentialCustomersArr));
                    let clickRateArr = res.data.map(item => {
                        return item.click_rate
                    })
                    this.maxclickData= Math.ceil(Math.max(...clickRateArr));
                    let conversionRateArr = res.data.map(item => {
                        return item.conversion_rate
                    })
                    this.maxconversion=Math.ceil(Math.max(...conversionRateArr));
                    // console.log(this.maxShowData,this.maxclickData,this.maxconversion);

                    this.allSeries = [
                        {
                            name: '展现次数',
                            type: 'line',
                            data: this.changeData(potentialCustomersArr),
                            smooth: true,
                             // symbolSize: 0,
                            itemStyle: {
                                normal: {
                                    color: "#E43A3C",
                                }
                            },
                            yAxisIndex: 1 // 与第二个 Y 轴关联
                        },
                        {
                            name: '点击率',
                            type: 'line',
                            data: this.changeData(clickRateArr),
                            smooth: true,
                            // symbolSize: 0,
                            itemStyle: {
                                normal: {
                                    color: "#26C6DA",
                                }
                            },
                            yAxisIndex: 0 // 与第一个 Y 轴关联
                        },
                        {
                            name: '转化率',
                            type: 'line',
                            data: this.changeData(conversionRateArr),
                            smooth: true,
                            // symbolSize: 0,
                            itemStyle: {
                                normal: {
                                    color: "#1E88E5",
                                }
                            },
                            yAxisIndex: 0 // 与第一个 Y 轴关联
                        }
                    ];
                    this.getTrendECharts()
                })
            },
            // 将等于0的数值改为0.1，不然会出现数据无法显示 原因是：yAxis这个的type: 'log'
            changeData(data) {
                return data.map(item => {
                    return item === 0 ? 0.1 : item
                })
            },
            // 趋势图
            getTrendECharts() {
                // 判断点击率
            //    let maxy0=this.maxclickData
               let index=this.series.findIndex(item=>item.name=='展现次数')
            //    let index2=this.series.findIndex(item=>item.name=='转化率')
            //    if(index!=-1 && index2 !=-1){
            //     maxy0=this.maxclickData>this.maxconversion?this.maxclickData:this.maxclickData
            //    }else{
            //     maxy0=index==-1?this.maxconversion:this.maxclickData
            //    } 
                this.boothAd.setOption({
                    xAxis: {
                        type: 'category',
                        data: ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
                            '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00' ],
                        boundaryGap: false,
                        axisLabel: {
                           
                            textStyle: {
                                color: '#666'
                            },
                            align: 'center'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: [
                    {
                        type: 'value',
                        position: 'left',
                      
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#666'
                            },
                            // formatter: function(v) {
                            //     return v.toFixed(2); //0表示小数为0位，1表示1位小数，2表示2位小数
                            // },
                        },
                        axisLine: {
                            show:false,
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            show:index==-1?true:false,
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    },
                    {
                        type: 'value',
                        position: 'right',
                       
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#666'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    },
                    ],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        backgroundColor: '#fff',
                        textStyle: {
                            color: '#666',
                        },
                        borderColor: '#DCDCDC',
                        borderWidth: 2,
                        formatter: (params) => {
                            let res = params[0].name;
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].seriesName === '点击率' || params[i].seriesName === '转化率') {
                                    res += `<br/>${params[i].marker}${params[i].seriesName}:${params[i].value === 0.1 ? 0 : params[i].value}%`; // 将0.1的值转为0
                                } else {
                                    res += `<br/>${params[i].marker}${params[i].seriesName}:${params[i].value === 0.1 ? 0 : params[i].value}`; // 将0.1的值转为0
                                }
                            }
                            return res
                        }
                    },
                    legend: {
                        icon: 'circle',
                        // data: this.checkList,
                        bottom: 0,
                        selectedMode: false
                    },
                    grid: {
                        top: 20,
                        left: 80,
                        right: 60,
                        bottom: 80,
                    },
                    series: this.series
                }, true);
            },
            // 获取地区分布数据
            getAreaList() {
                let params = {
                    op_id: this.$route.query.op_id,
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum
                }
                StuOpProGetLocationAnalysisData(params).then((res) => {
                    if (this.listPages.currentPageNum === 1) {
                        this.denominator = res.data.list[0].percent;
                        this.customerList = res.data.list.filter((item, index) => {
                            return index < 3
                        })
                    }

                    let arr = res.data.list.map(item => {
                        return {
                            click_rate: item.click_rate,
                            conversion_rate: item.conversion_rate,
                            index: item.index,
                            percent: item.percent,
                            potential_customers: item.potential_customers,
                            provinces: item.provinces,
                            percentage: Number((item.percent / this.denominator * 100 ).toFixed(2)),
                        }
                    })
                    this.areaList = arr
                    this.listPages.total = res.data.total
                })
            },
            //分页
            handleCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getAreaList()
            }
        }
    }
</script>

<style scoped lang="scss">
    .booth-ad-content {
        height: 100%;
        background-color: #f7f9fb;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .header {
            margin: 0 20px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #343434;
        }
        .area-header {
            display: flex;
            align-items: flex-end;
            margin: 20px;
            .focus-text {
                font-size: 14px;
                color: #343434;
                margin-left: 13px;
                font-weight: 400;
                .focus-city {
                    font-size: 14px;
                    color: #FF0000;
                }
            }
        }
        .chart-tab {
            margin: 20px 40px;
            display: flex;
            flex-direction: column;
            padding: 20px;
            background: #fff;
            .chart-tab-header {
                font-size: 16px;
                color: #FD4446;
                line-height: 1;
                display: flex;
                span {
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -10px;
                        height: 2px;
                        background: #FD4446;
                    }
                }
            }
            .chart-tab-title {
                margin: 35px 0 20px;
                font-size: 19px;
                line-height: 1;
                font-size: 18px;
                color: #333;
                font-weight: 600;
            }
            .tab-title {
                // padding-left: 15px;
                display: flex;
                align-items: center;
                // position: relative;
                // &:before {
                //     content: '';
                //     position: absolute;
                //     top: -2px;
                //     left: 0;
                //     width: 5px;
                //     height: 20px;
                //     background: #2DC079;
                //     border-radius: 3px;
                // }
                .popover-title {
                    font-size: 18px;
                    line-height: 1;
                    display: inline-block;
                    padding-right: 10px;
                    color: #333;
                    font-weight: 600;
                
                }
            }
            .data-content {
                height: 330px;
                margin-top: 30px;
            }
            // 多选框的颜色
            ::v-deep .el-checkbox-group {
                .el-checkbox {
                    .el-checkbox__input {
                        .el-checkbox__inner {
                            &:hover {
                                border-color: #0083FF;
                            }
                        }
                        &.is-focus {
                            .el-checkbox__inner {
                                border-color: #0083FF;
                            }
                        }
                    }
                    &.is-checked {
                        .el-checkbox__input {
                            &.is-checked {
                                .el-checkbox__inner {
                                    background-color: #0083FF;
                                    border-color: #0083FF;
                                }
                            }
                            &:hover {
                                border-color: #0083FF;
                            }
                        }
                        .el-checkbox__label {
                            color: #343434;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
        /*.area-table {*/
        /*    margin: 0 20px;*/
        ::v-deep .el-table {
            .el-table__body-wrapper {
                .el-table__row {
                    .ranking {
                        width: 24px;
                        height: 24px;
                        line-height: 24px;
                        text-align: center;
                        font-weight: bold;
                        background-size: cover;
                        display: inline-block;
                        font-style: italic;
                    }
                    &:first-child {
                        .ranking {
                            background: url("../../../../assets/images/student/num-001.png") no-repeat center;
                        }
                    }
                    &:nth-of-type(2) {
                        .ranking {
                            background: url("../../../../assets/images/student/num-002.png") no-repeat center;
                        }
                    }
                    &:nth-of-type(3) {
                        .ranking {
                            background: url("../../../../assets/images/student/num-003.png") no-repeat center;
                        }
                    }
                    .ranking-general {
                        line-height: 24px;
                        width: 24px;
                        height: 24px;
                        background: #E7F6EF;
                        border-radius: 50%;
                        display: inline-block;
                        text-align: center;
                    }
                }
            }
        }
        /*}*/
    }
    .big-title {
        font-size: 20px;
        line-height: 1;
        margin: 20px 40px;
        font-weight: 700;
       
    color: #343434;

    }
    .area-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .percentage {
            width: 50px;
            text-align: right;
            white-space: nowrap;
            margin-left: 10px;
        }
        .area-process {
            width: 1%;
            flex: 1;
        }
    }
    .operation-pagination {
        padding: 0;
        margin: 20px 0;
        text-align: center;
    }
</style>